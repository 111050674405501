<template>
  <v-data-table
    :headers="headers"
    hide-default-footer
    :items="items"
    :items-per-page="-1"
    sort-by="date"
    :sort-desc="true"
    must-sort
    @click:row="
      (item) =>
        $router.push({
          name: 'AttendanceCheck',
          params: {
            course: item.course.id,
            date: item.date,
            period: item.periods[0].id,
          },
        })
    "
  >
    <template v-slot:item.date="{ item }">
      <DateValue :value="item.date" />
    </template>
    <template v-slot:item.time="{ item }">
      {{ formatTimespan(item.date, item.startTime, item.date, item.endTime) }}
    </template>

    <template v-slot:item.periods="{ item }">
      {{ item.periods.length }}
    </template>
    <template v-slot:item.absences="{ item }">
      <PersonItem
        small
        v-for="absence in sortPeople(item.absences)"
        :key="'absence' + absence.id"
        :value="absence"
      />
    </template>
    <template v-slot:item.status="{ item }">
      <AttendanceCheckStatus :value="item" />
    </template>
    <template v-slot:footer
      ><TableFooter :items="items" label="Kontrollen" labelSingular="Kontrolle"
    /></template>
  </v-data-table>
</template>

<script>
import AttendanceCheckStatus from "@/components/AttendanceCheckStatus.vue";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "@/components/PersonItem.vue";
import TableFooter from "common/components/TableFooter.vue";
import { formatTimespan } from "common/utils/date.js";
import { sortPeople } from "common/utils/people.js";
export default {
  components: { AttendanceCheckStatus, DateValue, PersonItem, TableFooter },
  props: ["items"],
  data() {
    return {
      headers: [
        {
          text: "Datum",
          value: "date",
        },
        {
          text: "Zeit",
          value: "time",
        },
        {
          text: "L",
          value: "periods",
        },
        {
          text: "Kurs",
          value: "course.title",
        },
        {
          text: "Abwesenheiten",
          value: "absences",
        },
        {
          text: "",
          value: "status",
        },
      ],
    };
  },

  methods: {
    formatTimespan,
    sortPeople,
  },
};
</script>
