<template>
  <div class="mb-4">
    <template v-if="$vuetify.breakpoint.xs">
      <v-card class="mb-4" :loading="loading">
        <v-system-bar><v-spacer /> zu erledigen</v-system-bar>
        <AttendanceChecksList :items="itemsTodo" />
      </v-card>
      <v-card class="mb-4" :loading="loading">
        <v-system-bar>bereits erledigt</v-system-bar>
        <AttendanceChecksList :items="itemsDone" />
      </v-card>
    </template>
    <template v-else>
      <v-card class="mb-4" :loading="loading">
        <v-system-bar>zu erledigen</v-system-bar>
        <AttendanceChecksTable :items="itemsTodo" />
      </v-card>
      <v-card class="mb-4" :loading="loading">
        <v-system-bar>bereits erledigt</v-system-bar>
        <AttendanceChecksTable :items="itemsDone" />
      </v-card>
    </template>
  </div>
</template>

<script>
import AttendanceChecksList from "./components/AttendanceChecksList.vue";
import AttendanceChecksTable from "./components/AttendanceChecksTable.vue";
export default {
  components: { AttendanceChecksList, AttendanceChecksTable },
  props: { noredirection: { type: Boolean, default: false } },
  data() {
    return {
      items: [],
      loading: false,
    };
  },
  computed: {
    itemsTodo() {
      return this.items.filter((el) => !el.signed);
    },
    itemsDone() {
      return this.items.filter((el) => el.signed);
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.items = await this.apiList({
        resource: "absence/attendancechecks",
        query: "filter=active",
      });
      if (!this.noredirection) {
        const current = this.items.find((el) => el.current && !el.signed);
        if (current) {
          this.$router.push({
            name: "AttendanceCheck",
            params: {
              course: current.course.id,
              date: current.date,
              period: current.periods[0].id,
            },
          });
        }
      }
      this.items.sort((a, b) => this.compareItems(a, b));
      this.loading = false;
    },
    compareItems(a, b) {
      if (a.date > b.date) return -1;
      if (a.date < b.date) return 1;
      if (a.startTime > b.startTime) return -1;
      if (a.startTime < b.startTime) return 1;
      return 0;
    },
  },
  created() {
    this.fetchData();
  },
};
</script>
