<template>
  <v-list>
    <v-list-item
      v-for="(item, index) in items"
      :key="index"
      :class="item.current ? 'current' : ''"
      :to="{
        name: 'AttendanceCheck',
        params: {
          course: item.course.id,
          date: item.date,
          period: item.periods[0].id,
        },
      }"
    >
      <v-list-item-content>
        <v-list-item-title>
          {{ item.course.title }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <DateValue :value="item.date" long />,
          {{
            formatTimespan(item.date, item.startTime, item.date, item.endTime)
          }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-avatar
        ><AttendanceCheckStatus :value="item"
      /></v-list-item-avatar>
    </v-list-item>
  </v-list>
</template>

<script>
import AttendanceCheckStatus from '@/components/AttendanceCheckStatus.vue';
import DateValue from 'common/components/DateValue.vue';

import { formatTimespan } from 'common/utils/date';
export default {
  components: { AttendanceCheckStatus, DateValue },
  props: ['items'],
  methods: { formatTimespan },
};
</script>
